import { WixCodeApiFactoryArgs } from '@wix/thunderbolt-symbols'
import { namespace } from '../symbols'
import type {
	EnvironmentWixCodeSdkHandlers,
	EnvironmentWixCodeSdkSiteConfig,
	EnvironmentWixCodeSdkWixCodeApi,
} from '../types'

/**
 * This is SDK Factory.
 * Expose your Velo API
 */
export function EnvironmentSdkFactory({
	featureConfig,
	handlers,
}: WixCodeApiFactoryArgs<EnvironmentWixCodeSdkSiteConfig, unknown, EnvironmentWixCodeSdkHandlers>): {
	[namespace]: EnvironmentWixCodeSdkWixCodeApi
} {
	return {
		[namespace]: {
			someKey: featureConfig.someKey,
			doSomething: handlers.doSomething,
		},
	}
}
